import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import { styled } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
    }
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Upload(props) {

    const { text, size, color, variant, onChange, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
            component="label"
            role={undefined}
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
              Selecionar Arquivo
              <VisuallyHiddenInput
                type="file"
                onChange={onChange}
              />
        </MuiButton>
    )
}
