import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";

export function useForm(initialFValues, initialFErrors, validateOnChange = false, validate) {


    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState(initialFErrors);

    const handleInputChange = (e,valor) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const handleFileChange = (e, name) => {
      if (e.target.files && e.target.files.length > 0) {
        const { files } = e.target
        setValues({
          ...values,
          file: files[0],
          [name]: files[0].name
        })
        if (validateOnChange)
          validate({ file: files[0], [name]: files[0].name })
      }
    }

    const handleAutoCompleteChange = (name,value) => {
      setValues({
          ...values,
          [name]: value
      })
      if (validateOnChange)
          validate({ [name]: value })
    }

    const handleInputNumberFormatChange = (name,target) => {
      setValues({
          ...values,
          [name]: target.value
      })
      if (validateOnChange)
          validate({ [name]: target.value })
    }

    const handleMultipleChange = (e,valor) => {
      const { name, value } = e.target
        setValues({
            ...values,
            [name]: value.length>0 ? value.toString().trim() : undefined
        })
        if (validateOnChange)
            validate({ [name]: value.length>0 ? value.toString().trim() : undefined })
    };

    const resetForm = () => {
        setValues(initialFValues);
        setErrors(initialFErrors)
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleAutoCompleteChange,
        handleInputNumberFormatChange,
        handleMultipleChange,
        handleFileChange,
        resetForm

    }
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1),
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

